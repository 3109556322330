import React, { PropsWithChildren, ReactElement } from 'react';

import GreenBlobIcon from '../../images/icons/green-blob.svg';
import YellowBlobIcon from '../../images/icons/yellow-blob.svg';
import GreyBlobIcon from '../../images/icons/grey-blob.svg';
import OrangeBlobIcon from '../../images/icons/orange-blob.svg';
import PurpleBlobIcon from '../../images/icons/purple-blob.svg';
import styled from 'styled-components';
import { borderRadius, breakpoints } from '../../styles';

interface Props {
  color: 'green' | 'yellow' | 'grey' | 'orange' | 'purple';
}

const icons: Record<Props['color'], string> = {
  green: GreenBlobIcon,
  yellow: YellowBlobIcon,
  grey: GreyBlobIcon,
  orange: OrangeBlobIcon,
  purple: PurpleBlobIcon,
};

const offsetSize = '5rem';

const smOffsetSize = '1.5rem';

const BlobContainer = styled.div`
  display: flex;
  width: 100%;
`;

const StyledBlobImage = styled.img`
  margin-right: -100%;
  width: 100%;
  transition: 0.3s ease-in-out;
  ${BlobContainer}:hover > & {
    transform: scale(1.05);
  }
`;

const BlobChildrenContainer = styled.div`
  width: calc(100% - ${smOffsetSize});

  align-self: center;
  border-radius: ${borderRadius.lg};
  overflow: hidden;

  @media only screen and (min-width: ${breakpoints.sm}) {
    width: calc(100% - ${offsetSize});
  }
`;

const Blob = ({ color, children }: PropsWithChildren<Props>): ReactElement => {
  const blobIcon = icons[color];

  return (
    <BlobContainer>
      <StyledBlobImage src={blobIcon} />
      <BlobChildrenContainer>{children}</BlobChildrenContainer>
    </BlobContainer>
  );
};

export default Blob;
