import { FormattedMessage } from 'gatsby-plugin-intl';
import React, { ReactElement } from 'react';
import TextLoop from 'react-text-loop';
import { Col, Row } from 'react-flexbox-grid';
import styled from 'styled-components';
import scrollTo from 'gatsby-plugin-smoothscroll';

import {
  breakpoints,
  fontSize,
  fontWeight,
  grey,
  lineHeight,
  spacing,
  white,
} from '../../styles';
import Content from '../common/Content';
import Image from '../common/Image';
import WaveIcon from '../../images/icons/wave-icon.svg';
import Blob from '../patterns/Blob';
import Button from '../common/Button';

const LandingText = styled.h1`
  font-size: ${fontSize['5xl']};
  font-weight: ${fontWeight.bold};
  line-height: ${lineHeight.tight};
  line-height: 1;

  @media only screen and (min-width: ${breakpoints.sm}) {
    font-size: ${fontSize['6xl']};
  }
`;

const StyledSubTitle = styled.h2`
  font-size: ${fontSize['3xl']};

  @media only screen and (min-width: ${breakpoints.sm}) {
    font-size: ${fontSize['4xl']};
  }
`;

const LandingCardContainer = styled.div`
  background-color: ${grey['1']};
  padding-top: ${spacing['6']};
`;

const StyledWavePattern = styled.img`
  width: 100%;
  background-color: ${white};
  margin-bottom: -1rem;
`;

const StyledTextContainer = styled.div`
  padding: ${spacing['4']} ${spacing['2']};
  text-align: center;
  @media only screen and (min-width: ${breakpoints.sm}) {
    text-align: left;
    font-size: ${fontSize['3xl']};
  }
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: ${spacing['3']} 0 ${spacing['3']} ${spacing['1']};
  @media only screen and (min-width: ${breakpoints.sm}) {
    display: block;
  }
`;

const LandingCard = (): ReactElement => {
  return (
    <LandingCardContainer>
      <Content>
        <Row between="xs" middle="xs">
          <Col xs={12} md={6}>
            <StyledTextContainer>
              <LandingText>
                <FormattedMessage id="landingPage.landingCard.title" />
              </LandingText>
              <StyledSubTitle>
                <FormattedMessage id="landingPage.landingCard.desc" />
                <TextLoop>
                  <span>
                    <FormattedMessage id="landingPage.landingCard.helsinki" />
                  </span>
                  <span>
                    <FormattedMessage id="landingPage.landingCard.espoo" />
                  </span>
                  <span>
                    <FormattedMessage id="landingPage.landingCard.vantaa" />
                  </span>
                </TextLoop>
              </StyledSubTitle>
            </StyledTextContainer>
          </Col>
          <Col xs={12} md={6}>
            <Blob color="grey">
              <Image imgName="landing-card.jpg"></Image>
            </Blob>
          </Col>

          <Col xs={12} md={6}>
            <StyledButtonContainer>
              <Button onClick={() => scrollTo('#apartments')}>
                <FormattedMessage id="landingPage.landingCard.cta" />
              </Button>
            </StyledButtonContainer>
          </Col>
        </Row>
      </Content>
      <StyledWavePattern src={WaveIcon} />
    </LandingCardContainer>
  );
};

export default LandingCard;
