import React, { ReactElement } from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';
import styled from 'styled-components';

import { fontSize, maxWidth, spacing, white } from '../../styles';
import { Apartment } from '../../interfaces/common';
import Dots from '../patterns/Dots';
import ApartmentCard from './ApartmentCard';

const ApartmentCardContainer = styled.div`
  padding: ${spacing['6']} ${spacing['2']};
  margin: 0 auto;
`;

const ApartmentListContainer = styled.div`
  padding: ${spacing['6']} ${spacing['2']};
`;

const StyledTitle = styled.h2`
  padding: ${spacing['6']} 0;
  background-color: ${white};
  font-size: ${fontSize['4xl']};
  text-align: center;
  margin: 0;
`;

const StyledDotsContent = styled.div`
  max-width: ${maxWidth};
  margin: 0 auto;
`;

interface Props {
  apartments: Apartment[];
}

const ApartmentsList = ({ apartments }: Props): ReactElement => {
  if (apartments.length === 0) {
    return <div />;
  }

  return (
    <ApartmentListContainer id="apartments">
      <Dots>
        <StyledDotsContent>
          <StyledTitle>
            <FormattedMessage id="landingPage.apartmentList.title" />
          </StyledTitle>
        </StyledDotsContent>
      </Dots>
      {apartments.map(apartment => (
        <ApartmentCardContainer key={apartment.id}>
          <ApartmentCard apartment={apartment} />
        </ApartmentCardContainer>
      ))}
    </ApartmentListContainer>
  );
};

export default ApartmentsList;
