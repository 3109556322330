import React, { PropsWithChildren, ReactElement } from 'react';
import styled from 'styled-components';
import { white } from '../../styles';

const dotSpace = 0.75;
const dotSize = 0.3; // in rems
const StyledPattern = styled.div`
  background: linear-gradient(
        90deg,
        ${white} ${dotSpace - dotSize}rem,
        transparent 1%
      )
      center,
    linear-gradient(${white} ${dotSpace - dotSize}rem, transparent 1%) center,
    hsl(212, 33%, 92%);
  background-size: ${dotSpace}rem ${dotSpace}rem;
`;

const Dots = ({ children }: PropsWithChildren<unknown>): ReactElement => {
  return <StyledPattern>{children}</StyledPattern>;
};

export default Dots;
