import { FormattedMessage } from 'gatsby-plugin-intl';
import React, { ReactElement } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import styled from 'styled-components';
import {
  breakpoints,
  fontSize,
  fontWeight,
  lineHeight,
  spacing,
} from '../../styles';
import Image from '../common/Image';
import Blob from '../patterns/Blob';
import CheckIcon from '../../images/icons/check-icon.svg';

const Container = styled.div`
  width: 100%;
  padding: ${spacing['9']} 0;
`;

const StyledSectionTitle = styled.h2`
  text-align: center;
  font-size: ${fontSize['5xl']};
  font-weight: ${fontWeight.medium};
`;

const StyledCheckIcon = styled.img`
  width: 2rem;
  padding: ${spacing['2']};
  opacity: 0.7;
  @media only screen and (min-width: ${breakpoints.sm}) {
    padding: ${spacing['3']};
  }
`;

const StyledTitle = styled.h3`
  font-size: ${fontSize['3xl']};
  font-weight: ${fontWeight.medium};

  @media only screen and (min-width: ${breakpoints.sm}) {
    text-align: left;
    font-size: ${fontSize['4xl']};
  }
`;

const LandingText = styled.p`
  max-width: 25rem;
  font-weight: ${fontWeight.light};
  font-size: ${fontSize['xl']};
  padding-bottom: ${spacing['8']};
  line-height: ${lineHeight.regular};

  @media only screen and (min-width: ${breakpoints.sm}) {
    text-align: left;
  }
`;

const StyledList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const StyledBenefitListItem = styled.div`
  max-width: calc(100% - ${spacing['8']});
`;

const StyledListItem = styled.li`
  list-style: none;
  text-align: left;
  font-size: ${fontSize['2xl']};
  font-weight: ${fontWeight.light};
  padding: ${spacing['4']} 0;

  @media only screen and (min-width: ${breakpoints.sm}) {
    font-size: ${fontSize['3xl']};
  }
`;

const ListItem = ({ id }: { id: string }) => (
  <StyledListItem>
    <Row middle="xs" start="xs" style={{ margin: 0 }}>
      <StyledCheckIcon src={CheckIcon} />
      <StyledBenefitListItem>
        <FormattedMessage id={id} />
      </StyledBenefitListItem>
    </Row>
  </StyledListItem>
);

const AboutUsSection = (): ReactElement => {
  return (
    <>
      <StyledSectionTitle>
        <FormattedMessage id="landingPage.section.title" />
      </StyledSectionTitle>
      <Container>
        <Row center="xs" middle="xs">
          <Col xs={12} md={5}>
            <Blob color="green">
              <Image imgName="about-us-1.jpg" />
            </Blob>
          </Col>
          <Col xs={12} md={7}>
            <StyledList>
              <ListItem id="landingPage.aboutUs.benefit1" />
              <ListItem id="landingPage.aboutUs.benefit2" />
              <ListItem id="landingPage.aboutUs.benefit3" />
            </StyledList>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row center="xs" middle="xs">
          <Col xs={12} md={6}>
            <StyledTitle>
              <FormattedMessage id="landingPage.aboutUs.title" />
            </StyledTitle>
            <LandingText>
              <FormattedMessage id="landingPage.aboutUs.desc" />
            </LandingText>
            <StyledTitle>
              <FormattedMessage id="landingPage.aboutUs2.title" />
            </StyledTitle>
            <LandingText>
              <FormattedMessage id="landingPage.aboutUs2.desc" />
            </LandingText>
          </Col>
          <Col xs={12} md={6}>
            <Blob color="orange">
              <Image imgName="about-us-2.jpg"></Image>
            </Blob>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AboutUsSection;
