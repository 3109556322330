import React, { ReactElement } from 'react';

import Layout from '../layouts/layout';

import SEO from '../components/common/SEO';
import LandingCard from '../components/landing-page/LandingCard';
import ApartmentsList from '../components/landing-page/ApartmentsList';
import AboutUsSection from '../components/landing-page/AboutUsSection';
import { APARTMENTS } from '../services/apartments';
import Content from '../components/common/Content';

const apartments = APARTMENTS;

const IndexPage = (): ReactElement => (
  <Layout>
    <SEO title="Home" />
    <LandingCard />
    <Content>
      <AboutUsSection />
    </Content>
    <ApartmentsList apartments={apartments} />
  </Layout>
);

export default IndexPage;
